<template>
  <page-container is-show-breadcrumbs style="padding-bottom: 24px" class="custom-el-style">
    <div class="filter-box">
      <el-form
        inline
        :model="filterCondition"
        ref="filterCondition"
        size="medium"
        label-width="76px"
      >
        <el-form-item label="商城站点：">
          <el-select
            v-model="filterCondition.identity_dapp_id"
            clearable
            placeholder="请选择商城站点"
          >
            <el-option
              style="width: 280px !important"
              v-for="item in dapp_port_types"
              :key="item.dapp_id"
              :label="item.dapp_domain + '（' + item.dapp_name + '）'"
              :value="item.dapp_id"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="标识名称：">
          <el-input
            v-model="filterCondition.identity_name"
            placeholder="请输入标识名称"
            style="width: 210px"
          />
        </el-form-item>
        <el-form-item label="标识码：">
          <el-input
            v-model="filterCondition.identity_handle"
            placeholder="请输入标识码"
            style="width: 210px"
          />
        </el-form-item>
        <el-form-item style="margin-left: 5px">
          <el-button type="primary" @click="searchHandle">查询</el-button>
          <el-button
            type="primary"
            :loading="isLoad"
            :disabled="!filterCondition.identity_dapp_id"
            @click="synchronize"
          >
            同步商城商品
          </el-button>
        </el-form-item>
      </el-form>
    </div>
    <el-table
      :header-cell-style="{
        height: '60px',
        fontSize: '14px',
        fontWeight: 400,
        color: '#909399',
        background: '#FAFAFA',
      }"
      :data="listData" style="margin-top: 16px; font-size: 14px"
    >
      <!-- <el-table-column style="padding-left:16px" prop="identity_id" label="序号" width="180" /> -->
      <el-table-column prop="identity_cat_name" label="标识分类" min-width="100" />
      <el-table-column prop="identity_name" label="标识名称" min-width="200" />
      <el-table-column prop="identity_handle" label="标识码" min-width="150">
        <template v-slot="{ row }">
          <a target="_blank" :href="row.gwtUrl" style="color: #4f7afd; text-decoration: none">{{
            row.identity_handle
          }}</a>
        </template>
      </el-table-column>
      <el-table-column label="标识二维码" min-width="80" align="center">
        <template #header>
          标识二维码
          <el-tooltip
            class="item"
            effect="dark"
            content="信息完成工网通入网及区块链上链预计需要1小时左右"
            placement="top-start"
          >
            <i style="margin-left: 5px" class="iconfont icon-shuoming" />
          </el-tooltip>
        </template>
        <template v-slot="{ row }">
          <span
            class="iconfont icon-ico"
            v-if="row.identity_enter_status == 1 || row.isUpChain"
            @click="preview(row)"
          />
          <span style="color: #636363" v-else-if="row.identity_enter_status == 2">更新中</span>
          <span
            style="color: #ffa200"
            v-else-if="row.identity_enter_status == 0 && row.isUpChain == 0"
          >注册中</span>
        </template>
      </el-table-column>
    </el-table>
    <el-row>
      <pagination
        :page-index="filterCondition.pageIndex"
        :page-size="filterCondition.pageSize"
        :total="total"
        @sizechange="handleSizeChange"
        @currentchange="handleCurrentChange"
        style="margin-top: 24px"
      />
    </el-row>

    <!-- 预览下载工网通、上链二维码 -->
    <qr-code-dialog
      @close="dialogClose"
      :identity-id="identityId"
      :identity-type="identityType"
      :value="dialogVisible"
      :is-up-chain="isUpChain"
      :gwt-qrcode="gwtQrcode"
      :gwt-url="gwtUrl"
    />
  </page-container>
</template>
<script>
import PageContainer from "@/components/common/PageContainer.vue";
import qrCodeDialog from "@/components/common/qrCode.vue";
import { identityApi, webSiteApi } from "@/services/api";
export default {
  components: {
    PageContainer,
    qrCodeDialog
  },
  data() {
    return {
      isLoad: false,
      dialogVisible: false,
      isUpChain: 0, // 该数据是否上链
      identityId: 0, // 标识id
      identityType: 0, // 标识类型
      gwtQrcode: "", // 标识二维码路径
      gwtUrl: "", // 标识二维码数据流
      filterCondition: {
        pageIndex: 1,
        pageSize: 10,
        identity_name: "",
        cat_id: "",
        identity_dapp_id: "",
        identity_type: 2,
        identity_handle: ""
      },
      dapp_port_types: [],
      total: 0,
      listData: []
    };
  },
  created() {
    this.getList();
    this.getShopSite();
  },
  methods: {
    async getList() {
      let { dataList, pageInfo } = await identityApi.list(this.filterCondition);
      this.listData = dataList;
      this.total = pageInfo.total;
    },
    async getShopSite() {
      let { data } = await webSiteApi.getAll({ name_is_not_empty: 1 });
      this.dapp_port_types = data.shop;
    },
    searchHandle() {
      (this.filterCondition.pageIndex = 1), this.getList();
    },
    async synchronize() {
      if (!this.isLoad) {
        this.isLoad = true;
        let { code, data } = await identityApi.goodsIdentity({
          id: this.filterCondition.identity_dapp_id
        });
        if (code == 200 && data.createGoods && data.updateGoods) {
          this.isLoad = false;
          (this.filterCondition.pageIndex = 1), this.getList();
        }
      }
    },
    download(row) {
      console.log(row);
    },
    preview(row) {
      console.log("row", row);
      this.gwtQrcode = row.identity_code_data;
      this.gwtUrl = row.identity_code;
      this.identityId = row.identity_id;
      this.identityType = row.identity_type;
      this.isUpChain = row.isUpChain;
      this.dialogVisible = true;
    },
    dialogClose() {
      this.dialogVisible = false;
    },
    handleSizeChange(val) {
      this.filterCondition.pageSize = val;
      console.log(this.filterCondition);
      this.getList();
    },
    handleCurrentChange(val) {
      this.filterCondition.pageIndex = val;
      this.getList();
    }
  }
};
</script>
<style lang="less" scoped>
.filter-box {
  padding: 24px 20px 6px;
  background: var(--table-header-background);
  /deep/.el-table__body {
    font-size: 14px !important;
  }
}
/deep/.el-table__cell .cell {
  padding-left: 16px !important;
}
.table-state {
  .iconfont {
    cursor: pointer;
    margin-left: 8px;
    font-size: 16px;
    color: #7b7e8c;
  }
}
/deep/.el-tree-node__content {
  height: 34px !important;
  line-height: 34px;
}
.el-form {
  /deep/ .el-form-item {
    .el-form-item__label {
      padding-right: 0;
    }
  }
  /deep/.el-select .el-input {
    width: 250px !important;
  }
  /deep/ .el-button.el-button--primary {
    min-width: 80px;
  }
}
/deep/ .el-dialog {
  .el-dialog__header {
    border-radius: 4px;
  }
  .el-dialog__body {
    padding: 14px 24px;
  }
  .el-dialog__footer {
    padding-right: 24px;
    border-radius: 4px;
    padding-bottom: 24px;
    .el-button {
      width: 60px;
      height: 32px;
      padding: 8px 0;
      text-align: center;
    }
  }
}
.download {
  display: flex;
  align-items: flex-end;
  justify-content: center;
  .download_btn {
    color: #0f40f7;
    text-decoration: none;
    cursor: pointer;
    margin-left: 10px;
  }
}
/deep/.el-input--mini .el-input__inner {
  height: 32px;
}
/deep/.el-pagination__editor.el-input .el-input__inner {
  height: 30px;
}
.icon-ico {
  font-size: 22px;
  cursor: pointer;
  color: #636363 !important;
}
</style>

<template>
  <div>
    <el-dialog
      style="border-radius: 4px"
      @close="onClose"
      :visible.sync="isShow"
      :width="chainQrcode ? '758px' : '400px'"
    >
      <div class="left">
        <div class="top_ant">
          <img
            src="https://resource.iwanshang.cloud/test/mp_4cb2c0b0-c6ca-11ed-bfad-355e78a65777/21503d8d-3e3f-49a4-b99b-cd8412eb4e0a.png"
            alt=""
          />
          <div>
            <span class="title">工业互联网标识</span>
          </div>
        </div>
        <div class="text_desc">
          <span>本信息已加入国家工业互联网标识解析体系，不可假冒伪造</span>
        </div>
        <div class="qrcode_img_box">
          <el-image
            v-if="gwtUrl"
            class="qrcode_img"
            style="width: 170px; height: 170px"
            :src="gwtUrl"
          />
          <el-image
            v-else
            class="qrcode_img"
            style="width: 170px; height: 170px"
            src="https://resource.iwanshang.cloud/test/mp_4cb2c0b0-c6ca-11ed-bfad-355e78a65777/30aecbbb-cb6d-4b31-8f55-ecfcfa1139b0.png"
          />
        </div>
        <div class="qrcode_desc" v-if="gwtQrcode">
          <span> 请使用微信扫码查看工网通数字档案信息。 </span>
        </div>
        <div class="download_btn" v-if="gwtQrcode">
          <a id="link" download="工网通数字档案" :href="gwtQrcode">下载</a>
        </div>
      </div>
      <div class="right" v-if="chainQrcode">
        <div class="top_ant">
          <img
            src="https://resource.iwanshang.cloud/test/mp_4cb2c0b0-c6ca-11ed-bfad-355e78a65777/c0092b6c-9770-4d90-a85b-a795a253e7c2.png"
            alt=""
          />
          <div>
            <span class="title">区块链</span>
          </div>
        </div>
        <div class="text_desc">
          <span>本信息已在区块链存证， 区块链为您的购物保驾护航。</span>
        </div>
        <div class="qrcode_img_box">
          <el-image v-if="chainQrcode" class="qrcode_img" :src="chainQrcode" />
          <el-image
            v-else
            class="qrcode_img"
            style="width: 170px; height: 170px"
            src="https://resource.iwanshang.cloud/test/mp_4cb2c0b0-c6ca-11ed-bfad-355e78a65777/30aecbbb-cb6d-4b31-8f55-ecfcfa1139b0.png"
          />
        </div>
        <div class="qrcode_desc">
          <span v-if="chainQrcode"> 请使用支付宝扫码查看区块链内容。 </span>
        </div>
        <div class="download_btn">
          <a v-if="chainQrcode" id="link" download="区块链" :href="chainQrcode">下载</a>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { identityApi } from "@/services/api";
export default {
  data() {
    return {
      isShow: false,
      chainQrcode: "",
      chainUrl: "",
    };
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    identityId: {
      type: Number,
      default: 1,
    },
    identityType: {
      type: Number,
      default: 0,
    },
    isUpChain: {
      type: Number,
      default: 0,
    },
    gwtQrcode: {
      type: String,
      default: "",
    },
    gwtUrl: {
      type: String,
      default: "",
    },
  },
  watch: {
    value: {
      handler(newVal, oldVal) {
        if (newVal) {
          this.getblockchain();
        } else {
          this.isShow = false;
        }
      },
      immediate: true,
    },
  },
  created() {},
  methods: {
    async getblockchain() {
      if (!this.isUpChain) {
        this.chainQrcode = "";
        this.chainUrl = "";
        this.isShow = true;
        return;
      }

      await identityApi
        .getChainQrcode({
          businessType: this.identityType,
          businessId: this.identityId,
        })
        .then((res) => {
          this.chainQrcode = res.data.qrcode;
          this.chainUrl = res.data.url;
          this.isShow = true;
        });
    },
    onClose() {
      this.isShow = false;
      this.$emit("close", false);
    },
  },
};
</script>

<style lang="less" scoped>
/deep/ .el-dialog__body {
  display: flex;
  align-items: center;
  .left,
  .right {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 440px;
  }
}
.top_ant {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 38px;
  margin-bottom: 16px;
  img {
    margin-right: 5px;
  }
}
.text_desc {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  margin-bottom: 21px;
  width: 64%;
  color: #4e4c4c;
  text-align: center;
}
.qrcode_img_box {
  width: 221px;
  height: 221px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 15px;
  background: url("../../images/ant-link-bg.png") no-repeat;
  background-size: contain;
}
.qrcode_img_box .qrcode_img {
  width: 199px;
  height: 199px;
  text-align: center;
}
.qrcode_desc {
  color: #a5a5a5;
  margin-top: 21px;
  font-size: 14px;
  height: 19px;
  margin-bottom: 16px;
}
.download_btn {
  cursor: pointer;
  height: 19px;
  margin-left: 10px;
  a {
    text-decoration: none;
    color: #4f7afd;
  }
}
.top_ant div {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
img {
  width: 40px;
  height: 40px;
}
.title {
  font-size: 29px;
  font-weight: bold;
  color: #303133;
}
/deep/ .el-dialog__header {
  padding: 0;
  padding-bottom: 0;
  margin-right: 0;
}
/deep/ .el-dialog__body {
  padding-bottom: 30px !important;
}
</style>
